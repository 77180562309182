import { useEffect, useRef, useState } from "react";
import { Icon, Box } from "@storyofams/react-ui";
import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import styled from "styled-components";

import { Cross, Search } from "../../components/common/Icons";
import { track } from "~lib";

export const StyledInput = styled(Box)`
  appearance: none;
  display: inline-block;
  background-color: white;
  min-height: 48px;
  width: 100%;
  border-color: ${({ theme }) => theme.colors.grey200};
  border-width: 1px;
  border-style: solid;
  transition: border-color 0.18s ease-in-out, background-color 0.18s ease-in-out;
  text-decoration: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.grey400};
    text-overflow: ellipsis;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

interface SearchbarProps {
  hidden?: boolean;
}

export const SearchBar = ({ hidden }: SearchbarProps) => {
  const [isSent, setIsSent] = useState(false);
  const router = useRouter();
  const [search, setSearch] = useState(() => router?.query?.query || "");
  const posthog = usePostHog();
  const ref = useRef(null);

  useEffect(() => {
    if (router.query.query && router.isReady) setSearch(router.query.query);
  }, [router.isReady]);
  const setStateId = useRef<any>();
  const onSearchStateChange = (nextSearchState) => {
    clearTimeout(setStateId.current);
    setStateId.current = setTimeout(() => {
      const route = { query: { ...router.query, query: nextSearchState } };
      router.push(route, undefined, { shallow: true });
    }, 100);
    setSearch(nextSearchState);
  };
  return (
    <Box
      position="relative"
      display={hidden ? "none" : "block"}
      width={"100%"}
      ref={ref}
    >
      <form
        noValidate
        autoComplete="off"
        action=""
        role="search"
        onSubmit={(e) => {
          e.preventDefault();
          return false;
        }}
      >
        <StyledInput
          as="input"
          px={2}
          py={1.5}
          pr={5}
          fontSize={2}
          color="black"
          borderRadius="md"
          type="search"
          autoFocus={router.pathname !== "/search"}
          name="search"
          placeholder="Zoek naar product, inspiratie of antwoord"
          value={search}
          onChange={(e: any) => {
            onSearchStateChange(e.target.value);
            if (!isSent) {
              // posthog.capture("Typed in Search");
              setIsSent(true);
            }
          }}
          onBlur={(e) => {
            // if (router.pathname === "/search")
            //   posthog.capture("Modified search from search page");
            e.preventDefault();
            return false;
          }}
        />

        <Icon
          display="flex"
          alignItems="center"
          position="absolute"
          right={1.5}
          top={0}
          bottom={0}
          color="grey800"
          fontSize={2.5}
          as="button"
          type="button"
          icon={search ? Cross : Search}
          onClick={() => {
            if (search) {
              setSearch("");
              const route = { query: { ...router.query, query: "" } };
              router.push(route, undefined, { shallow: true });
            }
          }}
        />
      </form>
    </Box>
  );
};
